<template>
  <div class="container " style="max-width: 750px">

    <div class="level">
      <div class="level-left">
        <b-field class=" level-item">
          <p class="control">
            <b-button>Ingediend</b-button>
          </p>
          <p class="control">
            <b-button>Goedgekeurd</b-button>
          </p>
          <p class="control">
            <b-button>Afgewezen</b-button>
          </p>
        </b-field>
      </div>

      <div class="level-right">
        <b-button @click.stop="createModal" class="level-item" type="is-primary is-light" icon-left="plus-circle">Nieuwe
          aanvraag
        </b-button>
      </div>

    </div>


    <div class="container">

      <div v-for="submission in submissions" v-bind:key="submission.id" class="box" style="cursor: pointer">
        <article @click="$router.push({ name:'submission.detail', params: {id: submission.id}})" class="media">
          <div class="media-content">
            <div class="content">
              <div class="level mb-0">
                <div class="level-left">
                  <span class="is-size-5 has-text-weight-bold level-item">{{ submission.name }}</span>
                  <StatusComponent :status="submission.status" class="level-item"></StatusComponent>
                </div>
              </div>
              <small v-if="submission.user">Aangevraagd door {{ submission.user.first_name }}</small>
            </div>
          </div>

          <div class="media-right">
            <b-button @click.stop="deleteSubmission(submission.id)" size="is-small" type="is-danger" outlined
                      icon-right="delete"></b-button>
          </div>
        </article>

      </div>

      <div v-if="submissions.length === 0">

        <p>Je hebt nog geen aanvragen aangemaakt.</p>

      </div>


    </div>
  </div>
</template>

<script>
import StatusComponent from "@/components/StatusComponent";

export default {
  name: "SubmissionOverview",
  components: {StatusComponent},
  data() {
    return {
      submissions: []

    }
  },

  mounted() {
    this.$store.dispatch('setTitle', {
      message: "Aanvragen",
      back: false,
      stack: [{
        message: "Aanvragen"
      }]
    })
    this.fetchData()

  },

  methods: {

    fetchData() {
      this.$store.dispatch("fetchSubmissions").then(response => {
        this.submissions = response.data
      })
    },

    createModal() {
      this.$buefy.dialog.prompt({
        message: `Geef een titel aan je aanvraag`,
        inputAttrs: {
          placeholder: 'Project alpha v0.3',
          value: '',
          min: 1
        },
        trapFocus: true,
        onConfirm: (value) => this.createsubmission(value)
      })
    },

    createsubmission(value) {
      this.$store.dispatch("newSubmission", {
        'name': value,
      }).then((response) => {
        this.$buefy.snackbar.open({
          message: 'Aanvraag opgeslagen als concept.',
          type: 'is-success',
          position: 'is-top'
        })
        this.$router.push({name: 'submission.onboarding', params: {id: response.data.id}})

      })
    },

    deleteSubmission(id) {
      this.$store.dispatch("deleteSubmission", {
        id: id,
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Aanvraag verwijderd.',
          type: 'is-success',
          position: 'is-top'
        })
        this.fetchData()
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Aanvraag kon niet verwijderd worden.',
          type: 'is-danger',
          position: 'is-top'
        })
      })


    }

  }
}
</script>

<style scoped>

.box {
  transition: box-shadow .3s;

}

.box:hover {
  box-shadow: 0 0 12px rgba(33, 33, 33, .2);
}

</style>